<template>
  <v-container
      class="drawer-detection-container"
      fluid
      :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <CustomUsersTableComponent
      pAllowToCreateUser
      :pDisplayItems="displayUsers"
      :pCurrentPageLoading="currentPageLoading"
      :pTotalCount="totalCount"
      :pLocalStorageTable="localStorageTableSizeKey"
      :pStoreRef="'usersModule'"
      pShowUserType
      @onLoadPage="loadPage($event)"
      @onOpenEditDialog="openEditUserDialog($event)"
    />
    <UserDialog ref="editUserDialog" :pStoreRef="'usersModule'" @onAddOrSave="reloadPage" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "Users",

  components: {
    CustomUsersTableComponent: () => import("./custom-components/UsersTableComponent.vue"),
    UserDialog: () => import("./dialogs/UserDialog.vue"),
  },

  data: () => ({
    localStorageTableSizeKey: "users.table.size",
  }),

  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("usersModule", [
      "currentPageLoading",
      "currentPage",
      "displayUsers",
      "totalCount",
    ]),
  },

  methods: {
    async loadPage(currentPage) {
      try {
        const sentCurrentPage = {
          ...currentPage,
        };
        await this.$store.dispatch("usersModule/loadPage", sentCurrentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    async reloadPage() {
      try {
        await this.$store.dispatch("usersModule/reloadPage");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openEditUserDialog(user) {
      this.$refs.editUserDialog.openDialog(user);
    },
  },
};
</script>
